html {
    line-height: 1.15; /* 1 */
    text-size-adjust: 100%; /* 2 */
}

body {
    margin: 0;
    line-height: 1.15; /* 1 */
    text-size-adjust: 100%; /* 2 */
}

input[type="checkbox"] {
    transform: translateY(2px);
}
